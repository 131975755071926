import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import Vans from "../../pages/vans";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layoutold(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root} id={classes.headerc}>
        <>
          <Header  history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/trial/dashboard" component={Dashboard} />
            <Route path="/trial/vans" component={Vans} />
              <Route path="/trial/typography" component={Typography} />
              <Route path="/trial/tables" component={Tables} />
              <Route path="/trial/notifications" component={Notifications} />
              <Route
                exact
                path="/trial/ui"
                render={() => <Redirect to="/trial/ui/icons" />}
              />
              <Route path="/trial/ui/maps" component={Maps} />
              <Route path="/trial/ui/icons" component={Icons} />
              <Route path="/trial/ui/charts" component={Charts} />
            </Switch>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layoutold);
