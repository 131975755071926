import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

// components
import Layout from "./Layout";
import Layoutold from "./Layoutold"; 

// pages
import Error from "../pages/error";
import Login from "../pages/login";

import MainPage from "../pages";
import Inviz from "../pages/inviz";
import NotFoundPage from "../pages/404";
import LoginPage from "../pages/login";
import SignUpPage from "../pages/signup";
import ChartPageLine from "../pages/chartline";
import ChartPageLine2 from "../pages/chartline2";
// context
import { useUserState } from "../context/UserContext";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();

  return (
    <Router>
      <Switch>
         <Route exact path="/" component={MainPage}></Route>
           <Route exact path="/404"  component={NotFoundPage}></Route>
           <Route exact path="/login"  component={LoginPage}></Route>
           <Route exact path="/signup"  component={SignUpPage}></Route>
           
           <Route exact path="/trial/dashboard"  component={Layoutold}></Route>
           <Route exact path="/trial/vans"  component={Layoutold}></Route>
           <Route exact path="/trial/privacy"  component={Layout}></Route>
           <Route exact path="/trial/security"  component={Layout}></Route>
           <Route exact path="/trial/allowlist"  component={Layout}></Route>
           <Route exact path="/trial/parentalcontrol"  component={Layout}></Route>
           <Route exact path="/trial/analytics"  component={Layout}></Route>
           <Route exact path="/trial/denylist"  component={Layout}></Route>
           <Route exact path="/trial/logs"  component={Layout}></Route>
           <Route exact path="/trial/setup"  component={Layout}></Route>
           <Route exact path="/trial/account"  component={Layout}></Route>
           
        
         <Route component={Error} />
      </Switch>
    </Router>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/trial",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
