import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Vans from "../../pages/vans";
import Privacy from "../../pages/privacy";
import Apps from "../../pages/apps";
import Account from "../../pages/account";
import Security from "../../pages/security"; 
import Allowlist from "../../pages/allowlist";
import Parentalcontrol from "../../pages/parentalcontrol";
import Logs from "../../pages/logs";
import Denylist from "../../pages/denylist";
import Analytics from "../../pages/analytics";
//import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/trial/dashboard" component={Dashboard} />
              <Route path="/trial/vans" component={Vans} />
              <Route path="/trial/privacy" component={Privacy} />
              <Route path="/trial/security" component={Security} />
              <Route path="/trial/allowlist" component={Allowlist} />
              <Route path="/trial/parentalcontrol" component={Parentalcontrol} />
              <Route path="/trial/denylist" component={Denylist} />
              <Route path="/trial/logs" component={Logs} />
              <Route path="/trial/setup" component={Apps} />
              <Route path="/trial/account" component={Account} />
              <Route path="/trial/analytics" component={Analytics} />
              <Route path="/app/tables" component={Tables} />
              <Route path="/app/notifications" component={Notifications} />
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <Route path="/app/ui/maps" component={Maps} />
              <Route path="/app/ui/icons" component={Icons} />
              <Route path="/app/ui/charts" component={Charts} />
            </Switch>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
