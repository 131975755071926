import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";

// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "./components/Table/Table";
import BigStat from "./components/BigStat/BigStat";
import axios from "axios";
const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];
function gg(vv){
     //let jj=[];
     console.log(8888);
     console.log(vv);
     let ggj  = '[{"Title":"academia.edu","Url":"academia.edu:80/Documents/in/Footwear_and_Leather_Garments_and_Industries"},{"Title":"360doc.com","Url":"360doc.com:80/content/13/1022/21/1542087_323371966.shtml"},{"Title":"livedoor.jp","Url":"blog.livedoor.jp:80/alexthegreat"},{"Title":"constantcontact.com","Url":"archive.constantcontact.com:80/fs129/1102941867448/archive/1116462479736.html"},{"Title":"hatenablog.com","Url":"slimekingdom.hatenablog.com:80/category/u30b2u30fcu30e0"},{"Title":"blog.jp","Url":"japanican.blog.jp:80/archives/51463617.html"},{"Title":"biglobe.ne.jp","Url":"www2k.biglobe.ne.jp:80/~kao-nori/jawanote.cgi?page=0&&sgro"},{"Title":"theconversation.com","Url":"theconversation.com:80/ollies-at-the-olympics-why-having-skateboarding-at-tokyo-2020-is-a-winning-move-63349"},{"Title":"cafe24.com","Url":"conystoy.cafe24.com:80/index.php?mid=as&amp;page=2&amp;document_srl=2962&document_srl=105899&document_srl=108944"},{"Title":"denverpost.com","Url":"blogs.denverpost.com:80/style/2014/03"},{"Title":"shop-pro.jp","Url":"flashpoint.shop-pro.jp:80/?mode=f4"},{"Title":"clicrbs.com.br","Url":"wp.clicrbs.com.br:80/volume/2009/11/?topo=52,1,1,,219,e219&status=encerrado"},{"Title":"boston.com","Url":"realestate.boston.com:80/home-improvement/2020/05/11/a-designer-created-four-looks-for-the-same-home-office"},{"Title":"city-data.com","Url":"city-data.com:80/forum/fashion-beauty/1844757-men-wear-loafers-shorts-2.html"},{"Title":"libsyn.com","Url":"thepowellmovement.libsyn.com:80/2017/05"},{"Title":"findglocal.com","Url":"findglocal.com:80/MA/Casablanca/613998975309756/u0627u0644u0645u0639u062cu0628u0648u0646-u0628u0640-STYLE-vans"},{"Title":"seesaa.net","Url":"backside-tailslide.seesaa.net:80"},{"Title":"eklablog.com","Url":"longboard-longskate.eklablog.com:80/documentaire-c26089818/3"},{"Title":"pearltrees.com","Url":"pearltrees.com:80/coquito99/item149002136"},{"Title":"mtv.com","Url":"mtv.com:80/news/2516591/sneakers-prom-dress"}]';
     let myObjStr = vv;
     console.log(myObjStr)
     let oo = JSON.parse(ggj);
     console.log(oo);
     return oo
     //let obj3 = Object.entries(ggj).map(([key, value]) => ({key, value}))

      
     

}

export default function Vans(props) {
  var classes = useStyles();
  var theme = useTheme();

  // local
  var [mainChartState, setMainChartState] = useState("monthly");
  const [repos, setRepos] = React.useState([]);

  React.useEffect(() => {
    let TotSto=[];
    const fetchData = async () => {
        const response = `https://competes.co/pyapi/data/alexa?store_name=vans`;
        //setRepos(response.data);
        axios.get(response)
        .then(res=>{
          console.log(res.data.data);
          for(const dataObj of res.data.data){
            setRepos(dataObj.alexa_sitelinking);
            
        }
          //setRepos(TotSto);
            
          }).catch(errfirst=>{
            console.log(errfirst);  
        }
    )
    }

    fetchData();
  }, []);
  //console.log(288888888882); 
  console.log(repos);
  //console.log(repos.alexa_page_view);
  console.log(77777);
  let champObject =repos;
  //console.log(champObject);
  //for(const ff of champObject){ ff } 
  let pp = gg(champObject)
  //let myObject = JSON.parse(champObject);
  //const obj = Object.entries(champObject)

  //champObject.forEach(([key, value]) => console.log(key, value))
  let php =[];
 //var apidata = repos.data;
 //console.log(pp);
 for(const ff of pp){
   php.push(<li >{ff.Title}<br/>{ff.Url}</li>)
 }
 //console.log(php);
  return (
    <>
      
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h5"
                  color="text"
                  colorBrightness="secondary"
                >
                  Daily Line Chart
                </Typography>
                <div className={classes.mainChartHeaderLabels}>
                  <div className={classes.mainChartHeaderLabel}>
                    <Dot color="warning" />
                    <Typography className={classes.mainChartLegentElement}>
                      Tablet
                    </Typography>
                  </div>
                  <div className={classes.mainChartHeaderLabel}>
                    <Dot color="primary" />
                    <Typography className={classes.mainChartLegentElement}>
                      Mobile
                    </Typography>
                  </div>
                  <div className={classes.mainChartHeaderLabel}>
                    <Dot color="primary" />
                    <Typography className={classes.mainChartLegentElement}>
                      Desktop
                    </Typography>
                  </div>
                </div>
                <Select
                  value={mainChartState}
                  onChange={e => setMainChartState(e.target.value)}
                  input={
                    <OutlinedInput
                      labelWidth={0}
                      classes={{
                        notchedOutline: classes.mainChartSelectRoot,
                        input: classes.mainChartSelect,
                      }}
                    />
                  }
                  autoWidth
                >
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
              </div>
            }
          >
            <ResponsiveContainer width="100%" minWidth={500} height={350}>
              <ComposedChart
                margin={{ top: 0, right: -15, left: -15, bottom: 0 }}
                data={mainChartData}
              >
                <YAxis
                  ticks={[0, 2500, 5000, 7500]}
                  tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                  stroke={theme.palette.text.hint + "80"}
                  tickLine={false}
                />
                <XAxis
                  tickFormatter={i => i + 1}
                  tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                  stroke={theme.palette.text.hint + "80"}
                  tickLine={false}
                />
                <Area
                  type="natural"
                  dataKey="desktop"
                  fill={theme.palette.background.light}
                  strokeWidth={0}
                  activeDot={false}
                />
                <Line
                  type="natural"
                  dataKey="mobile"
                  stroke={theme.palette.primary.main}
                  strokeWidth={2}
                  dot={false}
                  activeDot={false}
                />
                <Line
                  type="linear"
                  dataKey="tablet"
                  stroke={theme.palette.warning.main}
                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.warning.dark,
                    strokeWidth: 2,
                    fill: theme.palette.warning.main,
                  }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Widget>
        </Grid>
        
        <Grid item xs={12}>
          
                 <div class="boxes-3">

<div class="box-area">
  <div class="box-inner">
    <div class="boxes-header">
      <h4>Related</h4>
      <div class="box-header-right">
        <div class="dropdown">
          <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Rising
          </a>
        
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something else here</a>
          </div>
        </div>
        <span class="material-icons download-ic">
          get_app
        </span>
        <div class="arrow-area">
          <span class="material-icons">
            chevron_left
            </span>
            <span class="material-icons">
              chevron_right
              </span>
        </div>
        <span class="material-icons share-ic">
          share
          </span>
      </div>
    </div>

    <div class="boxes-body">
      <ol>
        <li>Ross Stores - Discount store company <span>+120%</span></li>
        <li>HomeGoods - Discount store company <span>+110%</span></li>
        <li>Clothes shop - Topic <span>+90%</span></li>
        <li>Shop - Topic <span>+70%</span></li>
        <li>TJ Maxx - Department store company <span>+120%</span></li>
      </ol>
    </div>

    <div class="boxes-pagination">
      <span class="material-icons disabled">
        chevron_left
      </span>
      <span class="number">Showing 1-5 cs</span>
      <span class="material-icons">
        chevron_right
      </span>
    </div>

  </div>
</div>

<div class="box-area">
  <div class="box-inner">
    <div class="boxes-header">
      <h4>Related queries<span class="material-icons question-mark">
        help_outline
        </span></h4>
      <div class="box-header-right">
        <div class="dropdown">
          <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Rising
          </a>
        
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something else here</a>
          </div>
        </div>
        <span class="material-icons download-ic">
          get_app
        </span>
        <div class="arrow-area">
          <span class="material-icons">
            chevron_left
            </span>
            <span class="material-icons">
              chevron_right
              </span>
        </div>
        <span class="material-icons share-ic">
          share
          </span>
      </div>
    </div>

    <div class="boxes-body">
      <ol>
        <li>burlington coat factory <span>Breakout</span></li>
        <li>burlington country coror <span>Breakout</span></li>
        <li>burlington reopen <span>Breakout</span></li>
        <li>myapps burlington <span>+1050%</span></li>
        <li>yard house burling <span>+950%</span></li>
      </ol>
    </div>

    <div class="boxes-pagination">
      <span class="material-icons disabled">
        chevron_left
      </span>
      <span class="number">Showing 1-5 of 25 queries</span>
      <span class="material-icons">
        chevron_right
      </span>
    </div>

  </div>
</div>

<div class="box-area">
  <div class="box-inner">
    <div class="boxes-header">
      <h4>Related queries<span class="material-icons question-mark">
        help_outline
        </span></h4>
      <div class="box-header-right">
        <div class="dropdown">
          <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Rising
          </a>
        
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something else here</a>
          </div>
        </div>
        <span class="material-icons download-ic">
          get_app
        </span>
        <div class="arrow-area">
          <span class="material-icons">
            chevron_left
            </span>
            <span class="material-icons">
              chevron_right
              </span>
        </div>
        <span class="material-icons share-ic">
          share
          </span>
      </div>
    </div>

    <div class="boxes-body">
      <ol>
        <li>burlington coat factory <span>Breakout</span></li>
        <li>burlington country coror <span>Breakout</span></li>
        <li>burlington reopen <span>Breakout</span></li>
        <li>myapps burlington <span>+1050%</span></li>
        <li>yard house burling <span>+950%</span></li>
      </ol>
    </div>

    <div class="boxes-pagination">
      <span class="material-icons disabled">
        chevron_left
      </span>
      <span class="number">Showing 1-5 of 25 queries</span>
      <span class="material-icons">
        chevron_right
      </span>
    </div>

  </div>
</div>

</div>
          
        </Grid>
      </Grid>

      
      <Grid item xs={12}>
          
          <div class="boxes-3">

<div class="box-area secondrow">
<div class="box-inner">
<div class="boxes-header">
<h4>Related</h4>
</div>

<div class="boxes-body">
<ol>
{php}


</ol>
</div>

<div class="boxes-pagination">
<span class="material-icons disabled">
 chevron_left
</span>
<span class="number">Showing 1-10 cs</span>
<span class="material-icons">
 chevron_right
</span>
</div>

</div>
</div>

<div class="box-area secondrow">
<div class="box-inner">
<div class="boxes-header">
<h4>Related</h4>
</div>

<div class="boxes-body">
<ol>
 <li>Ross Stores - Discount store company </li>
 <li>HomeGoods - Discount store company </li>
 <li>Clothes shop - Topic </li>
 <li>Shop - Topic </li>
 <li>TJ Maxx - Department store company </li>
 <li>Clothes shop - Topic </li>
 <li>Shop - Topic </li>
 <li>TJ Maxx - Department store company </li>
 <li>HomeGoods - Discount store company </li>
 <li>Clothes shop - Topic </li>
 
 <li>Ross Stores - Discount store company </li>
 <li>HomeGoods - Discount store company </li>
 <li>Clothes shop - Topic </li>
 <li>Shop - Topic </li>
 <li>TJ Maxx - Department store company </li>
 <li>Clothes shop - Topic </li>
 <li>Shop - Topic </li>
 <li>TJ Maxx - Department store company </li>
 <li>HomeGoods - Discount store company </li>
 <li>Clothes shop - Topic </li>
</ol>
</div>

<div class="boxes-pagination">
<span class="material-icons disabled">
 chevron_left
</span>
<span class="number">Showing 1-10 cs</span>
<span class="material-icons">
 chevron_right
</span>
</div>

</div>
</div>


</div>
   
 </Grid>

    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray; 
}
