import React, { useState } from "react";
import './app.css';
//import React, { useState } from "react";
import {
    Grid,
    LinearProgress,
    Select,
    OutlinedInput,
    MenuItem,
  } from "@material-ui/core";
  import { useTheme } from "@material-ui/styles";
  import {
    ResponsiveContainer,
    ComposedChart,
    AreaChart,
    LineChart,
    Line,
    Area,
    PieChart,
    Pie,
    Cell,
    YAxis,
    XAxis,
  } from "recharts";
  
  // styles
  import useStyles from "./dashboard/styles";
  import ReactTooltip from "react-tooltip";
  //import { DropdownButton } from '@popperjs/core';
  //import "./styles.css";
  
  import MapChart from "./MapChart";
  // components
  import mock from "./dashboard/mock";
  import Widget from "../components/Widget";
  import PageTitle from "../components/PageTitle";
  import { Typography } from "../components/Wrappers";
  import Dot from "../components/Sidebar/components/Dot";
  import Table from "./dashboard/components/Table/Table";
  import BigStat from "./dashboard/components/BigStat/BigStat";
  
  const mainChartData = getMainChartData();
  const PieChartData = [
    { name: "Group A", value: 400, color: "primary" },
    { name: "Group B", value: 300, color: "secondary" },
    { name: "Group C", value: 300, color: "warning" },
    { name: "Group D", value: 200, color: "success" },
  ];

 
  
  // url to a valid topojson file
  //const geoUrl =
   // "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

//import Widget from "./pages/dashboard/components/Widget";
//import Widget from "../components/Widget";

export default function Parentalcontrol(props) {
    var classes = useStyles();
    var theme = useTheme();
    const [content, setContent] = useState("");
    // local
    var [mainChartState, setMainChartState] = useState("monthly");
    function shoot(e) {
      var el = document.getElementById('op1');
      el.classList.remove('_1fqn0');
      var el2 = document.getElementById('op2');
      el2.classList.remove('_1fqn0');
      e.currentTarget.classList.toggle('_1fqn0')
      } 

      function shoot1(e) {

        var el = document.getElementById('op');
        el.classList.remove('_1fqn0');
        var el2 = document.getElementById('op2');
        el2.classList.remove('_1fqn0');
        e.currentTarget.classList.toggle('_1fqn0')

     }
        function shoot2(e) {

            var el = document.getElementById('op1');
            el.classList.remove('_1fqn0');
            var el2 = document.getElementById('op');
            el2.classList.remove('_1fqn0');
            e.currentTarget.classList.toggle('_1fqn0')
            
            }
     function openmenu(e){
        // alert(222); 
         var el = document.getElementById('sidebarmenu');
         el.classList.toggle('opensidebar')
         var el2 = document.getElementsByClassName('_2THv1')[0]
         el2.classList.toggle('_8Oh3q') 
         var el21 = document.getElementsByClassName('_2Ei7e')[0]
         el21.classList.toggle('addtra')
     } 
     
     function openconfig(e){
        var el2 = document.getElementsByClassName('configdrop')[0]
        el2.classList.toggle('show') 
        var el2 = document.getElementsByClassName('menuwadd')[0]
        el2.classList.toggle('dropconfigsub') 
     }

     function submenuchnage(e){
        
         var url = '/trial/'+ e.target.value;
         window.location.href=url;
         
     }
     function timeshowdrop(){
        var el2 = document.getElementsByClassName('timeshowdrop')[0]
        el2.classList.toggle('show') 
        var el2 = document.getElementsByClassName('dropr')[0]
        el2.classList.toggle('show') 
     }

     function addclssd(e){
       //alert();
       //document.getElementsByClassName("bkl")[0].addEventListener('change', 'doThing');
       //var el2 = document.getElementsByClassName('bkl')[0]
       // el2.val(e.target.text) 
        document.getElementById("bkl").innerHTML = e.target.text;
        var el2 = document.getElementsByClassName('timeshowdrop')[0]
        el2.classList.toggle('show') 
        var el2 = document.getElementsByClassName('dropr')[0]
        el2.classList.toggle('show') 
        var el23 = document.getElementsByClassName('timea');
            //el23.classList.remove('active');
           // e.currentTarget.classList.add('active');
       // document.getElementsByClassName('dropdown-item').remove('active')
     }
  
  return (
    <>
   
         <div class="_2nR6t">
                <div class="_2NS96">
                    <div class="_1fhil"></div>
                    <div class="_1RiPb"></div>
                </div>
                <div class="_2Qk4f">
                   
                    <div class="_2RHK6 _14wL6">
                        <div class="_2kHSr _2jBtQ _3z7OM _3Uosj _1K3hm" id="sidebarmenu" >
                            <div class="_3hcLl" >
                                <div class="_1jc8P">
                                    <div class="_3iyER">
                                        <div class="ljYKJ logoname">
                                           Layer
                                        </div>
                                        <div class="_13epd">
                                            <div class="QPQ6k">
                                                <div class="_29AwW">
                                                    <div class="_ewpg _1fqn0" id="op" onClick={shoot}>
                                                        <div class="ORPh0">
                                                            <div class="_1ObBr">
                                                                <svg width="24" height="24" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        clip-rule="evenodd"
                                                                        d="M64 8L112 16L106.663 74.704C105.029 92.681 93.4472 108.221 76.6872 114.925L64 120L51.3128 114.925C34.5528 108.221 22.971 92.681 21.3367 74.704L16 16L64 8ZM24.6394 22.6705L29.3039 73.9797C30.6658 88.9605 40.3173 101.911 54.284 107.497L64 111.384L73.716 107.497C87.6827 101.911 97.3342 88.9605 98.6961 73.9797L103.361 22.6705L64 16.1103L24.6394 22.6705ZM32 30L64 24V104C49.3441 99.1147 38.8148 86.2221 36.9558 70.8856L32 30Z"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                            <div class="W5zWj">
                                                                <div class="wyuh-">
                                                                    <h3 class="_1VrMK">DNS</h3> 
                                                                    <svg  width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="UyUWa">
                                                                        <path
                                                                            d="M10.9996 7.50031C10.6104 7.88958 9.97933 7.88992 9.58963 7.50107L5.70502 3.62492L1.8204 7.50108C1.4307 7.88992 0.799674 7.88958 0.410401 7.50031C0.0208283 7.11073 0.0208285 6.47911 0.410402 6.08954L5.70502 0.794922L10.9996 6.08954C11.3892 6.47911 11.3892 7.11073 10.9996 7.50031Z"
                                                                        ></path>
                                                                    </svg>
                                                                </div>
                                                                <p class="_18u0w">Secure your digital life on all devices.</p>
                                                            </div>
                                                        </div>
                                                        <div class="_2zFLh submenu firstsub">
                                                <a class="_3KvtS _2zJ8_"  href="/trial/setup">Apps &amp; Setup</a>
                                                <a class="_3KvtS _2zJ8_  _3cnB6"  href="security">Security Controls</a>
                                                        
                                                    </div>
                                                        <div class="_3al5t"></div>
                                                    </div>
                                                    <div class="_ewpg" id="op1" onClick={shoot1}>
    <div class="ORPh0">
        <div class="_1ObBr">
            <svg width="24" height="24" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M64 8L112 16L106.663 74.704C105.029 92.681 93.4472 108.221 76.6872 114.925L64 120L51.3128 114.925C34.5528 108.221 22.971 92.681 21.3367 74.704L16 16L64 8ZM24.6394 22.6705L29.3039 73.9797C30.6658 88.9605 40.3173 101.911 54.284 107.497L64 111.384L73.716 107.497C87.6827 101.911 97.3342 88.9605 98.6961 73.9797L103.361 22.6705L64 16.1103L24.6394 22.6705ZM32 30L64 24V104C49.3441 99.1147 38.8148 86.2221 36.9558 70.8856L32 30Z"
                ></path>
            </svg>
        </div>
        <div class="W5zWj">
            <div class="wyuh-">
                <h3 class="_1VrMK">Tor</h3>
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="UyUWa">
                    <path
                        d="M10.9996 7.50031C10.6104 7.88958 9.97933 7.88992 9.58963 7.50107L5.70502 3.62492L1.8204 7.50108C1.4307 7.88992 0.799674 7.88958 0.410401 7.50031C0.0208283 7.11073 0.0208285 6.47911 0.410402 6.08954L5.70502 0.794922L10.9996 6.08954C11.3892 6.47911 11.3892 7.11073 10.9996 7.50031Z"
                    ></path>
                </svg>
            </div>
            <p class="_18u0w">Manage access to the Tor Network.</p>
        </div>
    </div>
    <div class="_2zFLh submenu">
                                                <a class="_3KvtS _2zJ8_ "  href="#">Setup</a>
                                                <a class="_3KvtS _2zJ8_ "  href="#">Bridges</a>
                                                        
                                                    </div>
    <div class="_3al5t"></div>
</div>

    
                                                    
                                                    <div class="_ewpg" id="op2" onClick={shoot2}>
                                                        <div class="ORPh0">
                                                            <div class="_1ObBr">
                                                                <svg width="24" height="24" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        clip-rule="evenodd"
                                                                        d="M64 8C94.9279 8 120 33.0721 120 64C120 94.9279 94.9279 120 64 120C33.0721 120 8 94.9279 8 64C8 33.0721 33.0721 8 64 8ZM64 16C37.4903 16 16 37.4903 16 64C16 90.5097 37.4903 112 64 112C90.5097 112 112 90.5097 112 64C112 37.4903 90.5097 16 64 16ZM78.5743 35.2112C75.545 31.7513 70.6419 30 63.9998 30C57.3577 30 52.4546 31.7513 49.4253 35.2096C46.3627 38.7031 46.2269 42.8501 46.2968 44.3793L46.3158 44.6893L46.3302 44.8526V52.5272L46.1784 52.7111C45.34 53.768 44.8723 55.0764 44.8723 56.4229V62.2525L44.8765 62.4781C44.941 64.2028 45.7422 65.7994 47.0748 66.8915C48.1945 71.2795 50.3103 74.7708 51.5572 76.5664L51.8575 76.9903L52.1587 77.3978V82.1568L52.1546 82.3312C52.0943 83.6048 51.376 84.7601 50.2488 85.3767L35.2522 91.4692L34.9421 91.6451C34.7357 91.767 34.53 91.8979 34.3244 92.0371L34.0436 92.2325L34 92.2711C41.4222 98.9417 53.2372 104 63.9998 104C74.7626 104 86.5779 98.9414 94 92.2705L93.9776 92.2512L93.796 92.4147C93.3128 92.0809 92.8262 91.7816 92.3282 91.5316L76.4112 85.5707L76.2575 85.4893C75.0987 84.8409 74.3862 83.6313 74.3862 82.2933V77.4307L74.5816 77.1857C74.8449 76.8507 75.1189 76.4832 75.4003 76.0872C77.2018 73.5416 78.6466 70.7099 79.7002 67.6627C81.7827 66.6728 83.1273 64.5975 83.1273 62.2541V56.4246L83.1224 56.1874C83.0679 54.8461 82.5526 53.5555 81.6693 52.5289V44.8543L81.6937 44.5479C81.7788 43.1753 81.757 38.8417 78.5743 35.2112Z"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                            <div class="W5zWj">
                                                                <div class="wyuh-">
                                                                    <h3 class="_1VrMK">Account Settings</h3>
                                                                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="UyUWa">
                                                                        <path
                                                                            d="M10.9996 7.50031C10.6104 7.88958 9.97933 7.88992 9.58963 7.50107L5.70502 3.62492L1.8204 7.50108C1.4307 7.88992 0.799674 7.88958 0.410401 7.50031C0.0208283 7.11073 0.0208285 6.47911 0.410402 6.08954L5.70502 0.794922L10.9996 6.08954C11.3892 6.47911 11.3892 7.11073 10.9996 7.50031Z"
                                                                        ></path>
                                                                    </svg>
                                                                </div>
                                                                <p class="_18u0w">Settings and more.</p>
                                                            </div>
                                                        </div>
                                                        <div class="_2zFLh submenu">
                                                <a class="_3KvtS _2zJ8_ "  href="/trial/account">Account settings</a>
                                                <a class="_3KvtS _2zJ8_ "  href="#">Subscription</a>
                                                        
                                                    </div>
                                                        <div class="_3al5t"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="_29JGy">
                        <div data-test="header" class="_3dJ9M">
				    																								<div class="_3daw4">
        <button type="button" tabindex="0" class="_3-mad _2Ei7e" onClick={openmenu}>
            <div class="_2THv1">
                <div class="_2jRz9"><div></div></div>
            </div>
        </button>
        
        <div class="dropdown configdrop">
        <button type="button" class="notranslate dropdown-toggle btn btn-light" onClick={openconfig}>My First Configuration</button>
        <div class="dropdown-menu menuwadd" >
            <a href="#" class="notranslate dropdown-item active">My First Configuration</a>
            <div class="dropdown-divider" role="separator"></div>
            <a href="#" class="text-primary dropdown-item" role="button">New Configuration</a>
        </div>
    </div>


    </div>
				    																																																																												<div class="_3VCAu">
        <a href="#" class="XwTk5 _3-mad">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="rJl7m">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17ZM4 12C4 7.58999 7.59 3.99999 12 3.99999C16.41 3.99999 20 7.58999 20 12C20 16.41 16.41 20 12 20C7.59 20 4 16.41 4 12ZM10 10H8C8 7.79 9.79 6 12 6C14.21 6 16 7.79 16 10C16 11.2829 15.21 11.9733 14.4408 12.6455C13.711 13.2833 13 13.9046 13 15H11C11 13.1787 11.9421 12.4566 12.7704 11.8217C13.4202 11.3236 14 10.8792 14 10C14 8.9 13.1 8 12 8C10.9 8 10 8.9 10 10Z"
                ></path>
            </svg>
        </a>
        <a href="#" class="XwTk5 _3-mad">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="rJl7m">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.333 6.667A2.666 2.666 0 0018.667 4H5.333a2.675 2.675 0 00-2.666 2.667v18.666C2.667 26.8 3.867 28 5.333 28h13.334c1.48 0 2.666-1.2 2.666-2.667v-4h-2.666v4H5.333V6.667h13.334v4h2.666v-4zm3.858 13.677a1.31 1.31 0 01-1.906-1.799l1.13-1.212H13.334a1.333 1.333 0 110-2.666h11.083l-1.131-1.212a1.31 1.31 0 011.906-1.799L29.333 16l-4.142 4.344z"
                ></path>
            </svg>
        </a>
    </div>
				</div>

                        

<div class="mt-4 pri">
    <div class="container">
    
    <div class="mb-3 row">
            <div class="col">
                <div class="card1">
                    <div class="card-body linkhead">
                        <a class="headerlinks" href="privacy">Privacy</a>
                        <a class="headerlinks" href="parentalcontrol">Parental controls</a>
                        <a class="headerlinks" href="security">Threat Protection</a>
                        <a class="headerlinks" href="denylist">Denylist</a>
                        <a class="headerlinks" href="allowlist">Allowlist</a>
                        <a class="headerlinks" href="logs">Logs</a>
                        <a class="headerlinks" href="analytics">Analytics</a>
                    </div>

                 <div class="mobile-sub-menu">
                        <select name="submenu" id="submenu" class="form-control" onChange={submenuchnage}>
                        <option>Select Submenu</option>
                        <option>Privacy</option>
                        <option value="parentalcontrol">Parental controls</option>
                        <option value="security">Threat Protection</option>
                        <option>Denylist</option>
                        <option>Allowlist</option> 
                        <option>Logs</option>
                        <option>Analytics</option>
                        </select>
                    </div>
                    
                </div>
            </div>
        </div>




<div class="row">
    <div class="mb-4 d-flex col">
        <div class="ckj">
            <div class="dropdown"><button aria-haspopup="true" aria-expanded="false" disabled="" type="button" class="text-truncate dropdown-toggle btn btn-light uiop" >All devices</button></div>
        </div>
        <div class="flex-grow-1 ml-3"></div>
        <div>
      
        <div class="dropdown timeshowdrop">
    <button onClick={timeshowdrop} aria-haspopup="true" aria-expanded="true" type="button" id="bkl" class="dropdown-toggle bkl btn btn-light">Last 30 minutes</button>
    <div
        x-placement="bottom-end"
        aria-labelledby=""
        class="dropdown-menu dropdown-menu-right dropr"
        
        data-popper-reference-hidden="false"
        data-popper-escaped="false"
        data-popper-placement="bottom-end"
    >
        <a href="#" onClick={addclssd} class="dropdown-item timea active" role="button">Last 30 minutes</a>
        <a href="#" onClick={addclssd}  class="dropdown-item timea" role="button">Last 6 hours</a>
        <a href="#" onClick={addclssd}  class="dropdown-item timea" role="button">Last 24 hours</a>
        <a href="#" onClick={addclssd}  class="dropdown-item timea" role="button">Last 7 days</a>
        <a href="#" onClick={addclssd}  class="dropdown-item timea" role="button">Last 30 days</a>
        <a href="#" onClick={addclssd}  class="dropdown-item timea" role="button">Last 3 months</a>
    </div>
</div>

        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-4">
        <div class="text-center py-2 mb-4 card">
            <div class="card-body">
                <div class="eqw">0</div>
                <div class="m12" >queries</div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="text-center py-2 mb-4 card">
            <div class="card-body">
                <div class="rp">0</div>
                <div class="m12">blocked queries</div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="text-center py-2 mb-4 card">
            <div class="card-body">
                <div class="ern">0%</div>
                <div class="m12"><span class="d-none"> </span><span>of blocked queries</span></div>
            </div>
        </div>
    </div>
</div>


<div class="row">
    <div class="col">
        <div class="mb-4 card">
            <div class="card-body">
                <h5>Queries</h5>
                <div  class="m12">Evolution of queries over time.</div>
                <div class="mt-3">
                <Grid item xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h5"
                  color="text"
                  colorBrightness="secondary"
                >
                  Daily Line Chart
                </Typography>
                <div className={classes.mainChartHeaderLabels}>
                  <div className={classes.mainChartHeaderLabel}>
                    <Dot color="warning" />
                    <Typography className={classes.mainChartLegentElement}>
                      Tablet
                    </Typography>
                  </div>
                  <div className={classes.mainChartHeaderLabel}>
                    <Dot color="primary" />
                    <Typography className={classes.mainChartLegentElement}>
                      Mobile
                    </Typography>
                  </div>
                  <div className={classes.mainChartHeaderLabel}>
                    <Dot color="primary" />
                    <Typography className={classes.mainChartLegentElement}>
                      Desktop
                    </Typography>
                  </div>
                </div>
                <Select
                  value={mainChartState}
                  onChange={e => setMainChartState(e.target.value)}
                  input={
                    <OutlinedInput
                      labelWidth={0}
                      classes={{
                        notchedOutline: classes.mainChartSelectRoot,
                        input: classes.mainChartSelect,
                      }}
                    />
                  }
                  autoWidth
                >
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
              </div>
            }
          >
            <ResponsiveContainer width="100%" minWidth={500} height={350}>
              <ComposedChart
                margin={{ top: 0, right: -15, left: -15, bottom: 0 }}
                data={mainChartData}
              >
                <YAxis
                  ticks={[0, 2500, 5000, 7500]}
                  tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                  stroke={theme.palette.text.hint + "80"}
                  tickLine={false}
                />
                <XAxis
                  tickFormatter={i => i + 1}
                  tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                  stroke={theme.palette.text.hint + "80"}
                  tickLine={false}
                />
                <Area
                  type="natural"
                  dataKey="desktop"
                  fill={theme.palette.background.light}
                  strokeWidth={0}
                  activeDot={false}
                />
                <Line
                  type="natural"
                  dataKey="mobile"
                  stroke={theme.palette.primary.main}
                  strokeWidth={2}
                  dot={false}
                  activeDot={false}
                />
                <Line
                  type="linear"
                  dataKey="tablet"
                  stroke={theme.palette.warning.main}
                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.warning.dark,
                    strokeWidth: 2,
                    fill: theme.palette.warning.main,
                  }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Widget>
        </Grid>
        
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row gridmock">
{mock.bigStat.map(stat => (
    <Grid item md={4} sm={6} xs={12} key={stat.product}>
      <BigStat {...stat} />
    </Grid>
  ))}
  </div>
<div class="row">
    <div class="col-lg-6">
        <div class="mb-4 card">
            <div class="list-group list-group-flush">
                <div class="py-3 list-group-item">
                    <h5>Resolved Domains</h5>
                    <div class="m12">Domains that resolved without being blocked by any setting or because they were manually allowed.</div>
                </div>
                <div class="text-center my-2 list-group-item op2" >No domains yet.</div>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="mb-4 card">
            <div class="list-group list-group-flush">
                <div class="py-3 list-group-item">
                    <h5>Blocked Domains</h5>
                    <div class="m12">Domains blocked by a Security, Privacy and/or Parental Control setting or because they were manually denied.</div>
                </div>
   <div class="text-center my-2 list-group-item op2" >No domains yet.</div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <div class="mb-4 card">
            <div class="list-group list-group-flush">
                <div class="py-3 list-group-item">
                    <h5>Blocked Reasons</h5>
                    <div class="m12">Security, Privacy and/or Parental Control settings that blocked the most queries.</div>
                </div>
                <div class="text-center my-2 list-group-item op2" >No domains were blocked yet.</div>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="mb-4 card">
            <div class="list-group list-group-flush">
                <div class="py-3 list-group-item">
                    <h5>Devices</h5>
                    <div class="m12">Devices making the queries.</div>
                </div>
   <div class="text-center my-2 list-group-item op2" >No domains yet.</div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <h5>Traffic Destination</h5>
                <div class="m12">Countries where your Internet traffic goes.</div>
                <div class="mt-3 mt-md-0">
      <MapChart setTooltipContent={setContent} />
      <ReactTooltip>{content}</ReactTooltip>
      </div>
            </div>
        </div>
    </div>
</div>
</div>

</div>


</div>
                    </div>
                </div>
                
            </div>
            
            </>
  );
}

function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
    var array = new Array(length).fill();
    let lastValue;
  
    return array.map((item, index) => {
      let randomValue = Math.floor(Math.random() * multiplier + 1);
  
      while (
        randomValue <= min ||
        randomValue >= max ||
        (lastValue && randomValue - lastValue > maxDiff)
      ) {
        randomValue = Math.floor(Math.random() * multiplier + 1);
      }
  
      lastValue = randomValue;
  
      return { value: randomValue };
    });
  }
  
  function getMainChartData() {
    var resultArray = [];
    var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
    var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
    var mobile = getRandomData(31, 1500, 7500, 7500, 1500);
  
    for (let i = 0; i < tablet.length; i++) {
      resultArray.push({
        tablet: tablet[i].value,
        desktop: desktop[i].value,
        mobile: mobile[i].value,
      });
    }
  
    return resultArray;
  }