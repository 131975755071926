import React,{Component} from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


class Trends extends Component{
    render(){
        var settings = {
         pauseOnHover:false,
         pauseOnFocus:false,
         draggable:false,
         autoplay: true,
         infinite: true,
         dots: false,
         arrows: false,
         vertical: true,
         slidesToShow: 3,
         slidesToScroll: 1,
         swipeToSlide:true,
         speed: 1000,
         autoplaySpeed: 2000,
         slidesToScroll:-1
          };
        return(
            
<div className="logo-list">
                <div className="c-home-notifications notifications-slider">
                    
                    <div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_1.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>Outbrain.com has been Blocked</p>
                                 <p class="light">Stopped 25 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div>    


 <div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_2.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>LiveRamp.com has been Blocked</p>
                                 <p class="light">Stopped 42 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div> 

 <div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_3.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>Criteo.com has been Blocked</p>
                                 <p class="light">Stopped 10 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>

</div> 

<div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_4.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>RubiconProject.com has been Blocked</p>
                                 <p class="light">Stopped 1 Hour Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div>

<div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_5.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>AppNexus.com has been Blocked</p>
                                 <p class="light">Stopped 20 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div> 

<div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_6.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>DoubleClick.net has been Blocked</p>
                                 <p class="light">Stopped 32 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div> 


  <div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_1.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>Outbrain.com has been Blocked</p>
                                 <p class="light">Stopped 25 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div>    


 <div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_2.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>LiveRamp.com has been Blocked</p>
                                 <p class="light">Stopped 42 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div> 

 <div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_3.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>Criteo.com has been Blocked</p>
                                 <p class="light">Stopped 10 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>

</div> 

<div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_4.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>RubiconProject.com has been Blocked</p>
                                 <p class="light">Stopped 1 Hour Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div>

<div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_5.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>AppNexus.com has been Blocked</p>
                                 <p class="light">Stopped 20 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div> 

<div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_6.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>DoubleClick.net has been Blocked</p>
                                 <p class="light">Stopped 32 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div> 


  <div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_1.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>Outbrain.com has been Blocked</p>
                                 <p class="light">Stopped 25 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div>    


 <div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_2.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>LiveRamp.com has been Blocked</p>
                                 <p class="light">Stopped 42 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div> 

 <div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_3.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>Criteo.com has been Blocked</p>
                                 <p class="light">Stopped 10 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>

</div> 

<div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_4.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>RubiconProject.com has been Blocked</p>
                                 <p class="light">Stopped 1 Hour Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div>

<div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_5.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>AppNexus.com has been Blocked</p>
                                 <p class="light">Stopped 20 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div> 

<div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_6.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>DoubleClick.net has been Blocked</p>
                                 <p class="light">Stopped 32 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div> 


  <div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_1.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>Outbrain.com has been Blocked</p>
                                 <p class="light">Stopped 25 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div>    


 <div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_2.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>LiveRamp.com has been Blocked</p>
                                 <p class="light">Stopped 42 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div> 

 <div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_3.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>Criteo.com has been Blocked</p>
                                 <p class="light">Stopped 10 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>

</div> 

<div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_4.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>RubiconProject.com has been Blocked</p>
                                 <p class="light">Stopped 1 Hour Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div>

<div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_5.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>AppNexus.com has been Blocked</p>
                                 <p class="light">Stopped 20 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div> 

<div class="notification-bubble ">
                           <div class="top">
                           <img src="assets/images/slide_6.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p>DoubleClick.net has been Blocked</p>
                                 <p class="light">Stopped 32 Minutes Ago</p>
                              </div>
                              <img src="assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div>
</div> 
        
                </div>
            </div>

        )
    }
}
export default Trends;