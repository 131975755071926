import React,{Component} from 'react';
import { NavLink } from 'react-router-dom';

class SignUp extends Component{
    constructor(props) {
		super(props);
		this.state = {
            opened: false,
            closed:true
		};
		this.toggleBox = this.toggleBox.bind(this);
	}
  
	toggleBox() {
        const { opened } = this.state;
        const { closed } = this.state;
		this.setState({
            opened: !opened,
            closed: !closed
		});
    }
render(){
    const { opened } = this.state;
        const { closed } = this.state;
    return(
         <div>
              <div class="main-outer-area signuppage">


<div class="main-content-area">
    <div class="main-banner inner">

        <div class="container">

            <header class="main-header d-flex justify-content-center">
                <div class="site-logo">
                <NavLink to="/">competes</NavLink>
                </div>
            </header>

        </div>

    </div>

    <div class="login-header">
        
    </div>

    <div class="login-container">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="login-wrap signup">
                        <p class="text-center step-title">Step 1 of 2</p>
                        <div class="custom-progress-bar">
                            <span></span>
                        </div>
                        {closed && (

                        <div id="step-1">
                        
                            <h4>Start your free competes trial</h4>

                            <div class="form-group">
                                <label for="work-email">Work email</label>
                                <input type="email" name="work-email" class="form-control" id="work-email" />
                            </div>

                            <div class="submit-btn">
                                <button id="go-to-nextStep"  onClick={this.toggleBox} class="btn btn-lg btn-flat btn-block btn-primary">Next</button>
                            </div>

                        </div>
                         )}
                         {opened && (

                        <div id="step-2" >
                        
                            <h4>You're nearly there</h4>
                        
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label for="first-name">First Name</label>
                                    <input type="text" name="first-name" class="form-control" id="first-name" />
                                </div>
                        
                                <div class="col-md-6 form-group">
                                    <label for="last-name">Last Name</label>
                                    <input type="text" name="last-name" class="form-control" id="last-name" />
                                </div>
                        
                            </div>   
                            
                            <div class="form-group">
                                <label for="phone-number">Phone Number</label>
                                <input type="tel" name="phone-number" class="form-control" id="phone-number" />
                            </div>

                            <div class="form-group">
                                <label for="job-title">Job Title</label>
                                <input type="text" name="job-title" class="form-control" id="job-title" />
                            </div>

                            <div class="submit-btn">
                        
                                <button class="btn btn-lg btn-flat btn-block btn-primary">Submit</button>

                            </div>
                        
                        </div>

)}

                    </div>
                   
                </div>
            </div>
        </div>
    </div>
 </div>  </div>
                 <footer class="main-footer">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <h3>Teleport To</h3>
                    <ul>
                        <li><a href="javascript:void(0)">Search</a></li>
                        <li><a href="javascript:void(0)">YouTube</a></li>
                        <li><a href="javascript:void(0)">Facebook</a></li>
                    </ul>
                </div>
                <div class="col-md-3">
                    <h3>Teleport To</h3>
                    <ul>
                        <li><a href="javascript:void(0)">Search</a></li>
                        <li><a href="javascript:void(0)">YouTube</a></li>
                        <li><a href="javascript:void(0)">Facebook</a></li>
                    </ul>
                </div>
                <div class="col-md-3">
                    <h3>About</h3>
                    <ul>
                        <li><a href="javascript:void(0)">Our Mission</a></li>
                        <li><a href="javascript:void(0)">We're Hiring</a></li>
                        <li><a href="javascript:void(0)">Terms</a></li>
                    </ul>
                </div>
                <div class="col-md-3">
                    <h3>Useful Links</h3>
                    <ul>
                        <li><a href="javascript:void(0)">Get Help (For Student)</a></li>
                        <li><a href="javascript:void(0)">Get Help (For Tutor)</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
    <div id="ioCookie" class="io-cookie io-cookie--active">
  <p data-test-id="cookie-banner-text" class="io-cookie-text">
    By using this website, you accept our <a data-test-id="cookie-policy-link" href="/cookie-policy">cookie policy</a>.
  </p>
  <a data-test-id="cookie-policy-button" class="io-cookie-button" id="io-cookie-button" onclick="acceptCookiePolicy()">
    <span class="io-cookie-close">Close</span>
  </a>
</div>
         </div>
    );
}

}

export default SignUp;
