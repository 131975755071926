import React from 'react';

const NotFoundPage = () => {

    return(
         <div>
             <h3>Not found</h3>
         </div>
    );
}

export default NotFoundPage;
