import React,{Component} from 'react';
import { NavLink } from 'react-router-dom';
import Trends from './partials/Trends';


class Inviz extends React.Component { 
   componentWillMount () {
      const script = document.createElement("script");
   
      script.src = "/assets/js/slick.min.js";
     // script.src = "/assets/js/main.js";
      script.async = true;
   
      document.body.appendChild(script);

      const script1 = document.createElement("script");
   
     // script1.src = "/assets/js/slick.min.js";
      script1.src = "/assets/js/main.js";
      script1.async = true;
   
      document.body.appendChild(script1);
    }
   render(){
 
    return(
        <div>
        <div class="main-banner invpage">
                <div class="container">
                <header class="main-header d-flex justify-content-between">
               <div class="site-logo">
                  <a href="javascript:void(0)"><img src="assets/images/layer_logonew.png" alt="logo-image" class="mainlogo" /></a>
               </div>
               <div class="menu-toggle-icon">
                  <span class="hamburger-inner"></span>
               </div>
               <div class="site-login">
                  <div class="menu-toggle-bg"></div>
                  <div class="site-login-inner">
                     <div class="site-logo menu-with-logo">
                        <a href="javascript:void(0)">competes</a>
                     </div>
                      
                      
                  </div>
               </div>
            </header>
                
            <div class="row justify-content-center">
               <div class="col-md-7">
               <div class="banner-content text-center">
                     <h1 class="banner-title">Full Encryption and Freedom through DNS and Tor</h1>
                     <div class="banner-description">
                        <p>No sign up required</p>
                     </div>
                     <div class="banner-action" >
                     <div class="input-group">
                           
                     <div class="input-group-append">
                              <a href="javascript:void(0)" class="btn btn-lg btn-flat btn-primary">Coming Soon</a>
                           </div>
                    
                           </div>
                           </div>
                     </div>
               
               
               </div> </div>
               <h2 class="banner-subheading text-center" >Protect Your Privacy and Remove Ads.</h2>
                </div>
                </div>

                <div class="call-to-action">
                <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-7">
                        <div class="pl-5">
                            <h2 class="freeh2">DNSCrypt Plus Tor</h2>
                            <p class="pclass">Layer combines DNSCrypt and Tor to achieve maximum security and privacy.</p>
                        </div>
                    </div>
                    <div class="col-md-5">
                        
                        <NavLink to="/signup" className="btn btn-lg btn-flat btn-dark">Try Now</NavLink>
                    </div>
                </div>
                </div>
                </div>
                
                <div class="connecting-trends first-trends">
         <div class="container">
            <div class="row align-items-center rotate-in-mobile">
            <div class="col-md-6">
            <Trends />
                     </div>
            
            
            <div class="col-md-6">
               <div class="section-content">
                <h2>Block ads, trackers, <br />malware, phishing <br />websites and more.</h2>
                  <p>Choose from trusted blocklists to block ads and malicious websites. Blocklists are updated in real-time and you can customize your own blocklists with unlimited blacklisting and whitelisting of specific domains.</p>
                  <a href="javascript:void(0)" class="btn-link green">Try Now &nbsp; ></a>
               </div> </div>
            </div>

            </div>
      </div>

      <div class="connecting-trends sect" >
         <div class="container">
            <div class="row align-items-center">
               <div class="col-md-6">
                  <div class="section-content">
                  <h2>Surf the Web <br />Anonymously with Tor</h2>
                     <p>For an additional layer of privacy, you can access the Tor network. When enabled, you will ensure your privacy through the Tor Network. Your internet traffic is relayed and encrypted, no ISP or website knows where you are connecting from.</p>
                     <a href="javascript:void(0)" class="btn-link green">Try Now &nbsp; ></a>
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="logo-list">
                     <div class="row c-home-notifications">
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/facebook-logo.png" alt="Facebook" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/google-ads.png" alt="Google Ads" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/snapchat.png" alt="snapchat" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/google-marketing-platform.png" alt="Google marketing platform" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/twitter-logo.png" alt="Twitter" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/braze-logo.png" alt="Braze" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/google-big-query.png" alt="Google Big Query" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/amazon-kinesis.png" alt="Amazon Kinesis" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/salesforce-logo.png" alt="Salesforce Marketing Cloud" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/Mixpanel_logo.png" alt="Mix Panel" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/remerge-logo.png" alt="Remerge" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/Live-Ramp-Logo.png" alt="Live Ramp" /></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="intro-sections">
         
         <section class="intro-section sec">
            <div class="container">
               <div class="row">
                  <div class="col-md-5">
                     <strong class="label">Never Be Unprotected Again</strong>
                     <h2>Protect All Your Devices</h2>
                     <p>Enjoy the privacy and security benefits of Layer on all your devices.Layer has apps for Android and Chrome OS with support for iOS, Windows, and macOS coming soon.</p>
                     <a href="javascript:void(0)" class="btn-link gray">Try Now &nbsp; ></a>
                  </div>
                  <div class="col-md-7 text-right">
                     <img src="assets/images/map-img.jpg" alt="map-image" />
                  </div>
               </div>
            </div>
         </section>
      </div>
      <div class="vector-graphic">
      </div>
      <div class="left-behind">
         <div class="container">
           
         </div>
      </div>
      <footer class="main-footer">
         <div class="container">
            <div class="row">
               <div class="col-md-3">
               <h3>&nbsp;&nbsp;&nbsp;</h3>
                  
               </div>
               <div class="col-md-3">
                  <h3>&nbsp;&nbsp;&nbsp;</h3>
                  
               </div>
               <div class="col-md-3">
                  <h3>About</h3>
                  <ul>
                     <li><a href="faq">FAQ</a></li>
                     <li><a href="javascript:void(0)">Privacy</a></li>
                     <li><a href="javascript:void(0)">Terms</a></li>
                  </ul>
               </div>
               <div class="col-md-3">
                 <h3>&nbsp;&nbsp;&nbsp;</h3>
               </div>
            </div>
         </div>
      </footer>
                </div>
    );
}
}

export default Inviz;
