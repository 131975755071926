import React,{Component} from 'react';
import { NavLink } from 'react-router-dom';

class LoginPage extends Component {
    render(){

    return(
         <div>
             <div class="main-outer-area loginpage">


<div class="main-content-area ">
    
    <div class="main-banner inner">

        <div class="container">

            <header class="main-header d-flex justify-content-center">
                <div class="site-logo">
                <NavLink to="/">competes</NavLink>
                </div>
            </header>

        </div>

    </div>

    <div class="login-header">
        
    </div>

    <div class="login-container">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-9">
                    <div class="login-wrap text-center">
                        <h2>Login to Competes</h2>
                        <div class="login-btns">
                            <a href="javascript:void(0)" class="btn btn-gray">
                                
                                <input type="text" class="form-control" placeholder="Email" />
                            </a>
                            <a href="javascript:void(0)" class="btn btn-gray">
                               
                               
                                <input type="text" class="form-control" placeholder="Password" />
                            </a>
                         
                            
                        </div>

                        <div class="signup-here">
                            New to Competes? <a href="#">Signup here</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div> </div>
                 <footer class="main-footer">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <h3>Teleport To</h3>
                    <ul>
                        <li><a href="javascript:void(0)">Search</a></li>
                        <li><a href="javascript:void(0)">YouTube</a></li>
                        <li><a href="javascript:void(0)">Facebook</a></li>
                    </ul>
                </div>
                <div class="col-md-3">
                    <h3>Teleport To</h3>
                    <ul>
                        <li><a href="javascript:void(0)">Search</a></li>
                        <li><a href="javascript:void(0)">YouTube</a></li>
                        <li><a href="javascript:void(0)">Facebook</a></li>
                    </ul>
                </div>
                <div class="col-md-3">
                    <h3>About</h3>
                    <ul>
                        <li><a href="javascript:void(0)">Our Mission</a></li>
                        <li><a href="javascript:void(0)">We're Hiring</a></li>
                        <li><a href="javascript:void(0)">Terms</a></li>
                    </ul>
                </div>
                <div class="col-md-3">
                    <h3>Useful Links</h3>
                    <ul>
                        <li><a href="javascript:void(0)">Get Help (For Student)</a></li>
                        <li><a href="javascript:void(0)">Get Help (For Tutor)</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
         </div>
    );
}

}

export default LoginPage;
